import { Button, Table } from "antd"
import React, { useContext, useEffect, useState } from "react"
import HeaderTable from "./HeaderTable"
import moment from "moment"
import { itemRender } from "../../../../customUtil/table"
import "./style.scss"
import { SuperAdSiteContext } from "../.."
import { getListCampaign } from "../../../../services/campaign"
import { defineTableListCampaign } from "./defineTableListCampaign"
import { paginationCustom } from "../../../../components/paginationCustom"

const dataSource = [
  {
    key: "1",
    status: "編集中",
    start_date: Date.now(),
    end_date: Date.now(),
    catch: "キャンペーンテキスト",
    discount_rate: "10%",
    internal_memo: "テキストテキスト",
  },
  {
    key: "2",
    status: "終了",
    start_date: Date.now(),
    end_date: Date.now(),
    catch: "キャンペーンテキスト",
    discount_rate: "10%",
    internal_memo: "テキストテキスト",
  },
  {
    key: "3",
    status: "実施中",
    start_date: Date.now(),
    end_date: Date.now(),
    catch: "キャンペーンテキスト",
    discount_rate: "10%",
    internal_memo: "テキストテキスト",
  },
]

const index = () => {
  const { campaignType, keyPage } = useContext(SuperAdSiteContext)
  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [listCampaign, setListCampaign] = useState(null)
  const [searchParams, setSearchParams] = useState({})

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const handleGetListCampaign = async () => {
    setIsLoading(true)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      q: searchParams.keyword ? searchParams.keyword : null,
    }
    const { response } = await getListCampaign(dataSent)
    if (response.status == 200) {
      setListCampaign(response.data.discounts)
      setTotal(response.data.count)

      setIsLoading(false)
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })
  }

  useEffect(() => {
    if (listCampaign) {
      handleGetListCampaign()
    }
  }, [tableParams])

  useEffect(() => {
    if (campaignType == "list" && keyPage == 2) {
      handleGetListCampaign()
    }
  }, [campaignType, keyPage])

  useEffect(() => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    })
  }, [searchParams])

  const onSearchSubmit = (params) => {
    setSearchParams(params)
  }
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const columns = defineTableListCampaign(handleGetListCampaign)

  return (
    <div className="mt-4">
      <HeaderTable
        onSubmit={onSearchSubmit}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        handleGetListCampaign={handleGetListCampaign}
      />
      <div className="confirmTable">
        <Table
          columns={columns}
          scroll={{ x: 1200 }}
          dataSource={listCampaign}
          loading={isLoading}
          rowSelection={rowSelection}
          rowKey="id"
          onChange={handleTableChange}
          pagination={{
            ...tableParams.pagination,
            total: total,
            itemRender: paginationCustom,
            showSizeChanger: false,
            showTotal: (total, range) => (
              <div>
                {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                {total}件)
              </div>
            ),
          }}
        />
      </div>
    </div>
  )
}

export default index
