import { Button, Spin, Modal, Form, Input } from "antd"
import React, { useCallback, useEffect, useState } from "react"
import upIcon from "../../../images/upIcon.svg"
import downIcon from "../../../images/downIcon.svg"
import closeIcon from "../../../images/closeIcon.svg"
import {
  addProductTag,
  deleteProductTag,
  editProductTag,
  getAllProductTag,
} from "../../../services/user"
import {
  openNotificationFail,
  openNotificationSuccessNG,
} from "../../../components/notification"
import textAndRules from "../../../utils/textAndRules"

const CustomRow = ({ index, data, id, title, option, setData, setLoading }) => {
  const [visibleModal, setVisibleModal] = useState(false)

  const handleDelete = () => {
    setVisibleModal(true)
  }

  const handleOkModalRemove = async () => {
    setLoading(true)
    const res = await deleteProductTag({
      tagId: id,
    })
    if (res.data) {
      openNotificationSuccessNG("位置を変更しました。", "", "green")
      setData((prev) => ({
        count: prev.count - 1,
        productTag: prev.productTag.filter((item) => item.id !== id),
      }))
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "red"
      )
    }
    setLoading(false)
  }

  const handleCancelModalRemove = () => {
    setVisibleModal(false)
  }

  const handleDown = async () => {
    setLoading(true)
    const res = await editProductTag({
      tagIds: [data?.productTag[index].id, data?.productTag[index + 1].id],
    })
    if (res.data) {
      openNotificationSuccessNG("位置を変更しました。", "", "green")
      setData((prev) => {
        const newData = [...prev.productTag]
        const temp = newData[index]
        newData[index] = newData[index + 1]
        newData[index + 1] = temp
        return {
          count: prev.count,
          productTag: newData,
        }
      })
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "red"
      )
    }
    setLoading(false)
  }

  const handleUp = async () => {
    setLoading(true)
    const res = await editProductTag({
      tagIds: [data?.productTag[index].id, data?.productTag[index - 1].id],
    })
    if (res.data) {
      openNotificationSuccessNG("位置を変更しました。", "", "green")
      setData((prev) => {
        const newData = [...prev.productTag]
        const temp = newData[index]
        newData[index] = newData[index - 1]
        newData[index - 1] = temp
        return {
          count: prev.count,
          productTag: newData,
        }
      })
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "red"
      )
    }
    setLoading(false)
  }

  return (
    <div className="flex justify-between pt-[13.5px] pb-[18.5px] border-b border-[rgba(0,0,0,0.06)]">
      <div className="flex items-center">
        <span className="text-[#BCBCBC] text-sm font-normal w-[28px]">
          {index + 1}
        </span>
        <span className="text-[#343434] text-sm font-normal">{title}</span>
      </div>
      {option && (
        <div
          className="flex items-center border-l border-[rgba(204,204,204,0.5)] pl-4 
              child:border child:border-primary-90 child:rounded-sm child:w-[25px] 
              child:h-[25px] child:flex child:items-center child:justify-center h-8"
        >
          <button onClick={handleDown} disabled={index + 1 === data?.count}>
            <img src={downIcon} alt="" />
          </button>
          <button
            className="mx-[10px]"
            disabled={index === 1}
            onClick={handleUp}
          >
            <img src={upIcon} alt="" />
          </button>
          <button onClick={handleDelete}>
            <img src={closeIcon} alt="" />
          </button>
        </div>
      )}
      <Modal
        title="この画像を削除してもよろしいでしょうか？"
        okText="削除"
        cancelText="キャンセル"
        centered
        visible={visibleModal}
        onOk={handleOkModalRemove}
        onCancel={handleCancelModalRemove}
      />
    </div>
  )
}

const MasterData = ({ keyPage }) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)

  const [form] = Form.useForm()

  const getProductTags = useCallback(async () => {
    setLoading(true)
    const res = await getAllProductTag({ typeTag: "allergy" })
    if (res.data) {
      setData(res.data)
    }
    setLoading(false)
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    const res = await addProductTag({
      ...values,
      index: data.count,
      type: "allergy",
    })
    form.setFieldsValue({
      value: "",
    })
    if (res.data) {
      openNotificationSuccessNG("位置を変更しました。", "", "green")
      getProductTags()
    } else {
      setLoading(false)
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "red"
      )
    }
    setOpen(false)
  }

  useEffect(() => {
    if (keyPage == 4) {
      getProductTags()
    }
  }, [keyPage])

  return (
    <Spin spinning={loading}>
      <div className="p-[50px] pt-10 pb-[66px] bg-grey-0">
        {data?.productTag?.map((item, index) => (
          <CustomRow
            key={item.id}
            id={item.id}
            index={index}
            title={item.value}
            option={true}
            data={data}
            setData={setData}
            setLoading={setLoading}
          />
        ))}
        <div className="mt-[10px]">
          <Button
            type="danger"
            className="h-[30px] w-[200px]"
            onClick={() => setOpen(true)}
          >
            ＋ 追加
          </Button>
        </div>
      </div>
      <Modal
        title="追加アレルギー成分マスタ"
        centered
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={600}
        footer={false}
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item
            name="value"
            rules={[
              {
                required: true,
                message: textAndRules.pleaseInput,
              },
              {
                whitespace: true,
                message: "空白を入力できません。",
              },
            ]}
          >
            <Input placeholder="アレルギー名" />
          </Form.Item>
          <div className="text-center">
            <Button htmlType="submit" type="primary" className="!w-[200px]">
              追加
            </Button>
          </div>
        </Form>
      </Modal>
    </Spin>
  )
}

export default MasterData
