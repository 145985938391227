import React, { useEffect, useState } from "react"
import { Button, Space, Table, Popconfirm, Typography, Tooltip } from "antd"
import AddNewZoomLive from "./AddNewZoomLive"
import { delteZoom, getListZoom } from "../../../services/zoom"
import moment from "moment"
import { Link } from "gatsby"
import { openNotificationFail } from "../../../components/notification"
import { paginationCustom } from "../../../components/paginationCustom"
const { Paragraph } = Typography
const ZoomLiveManagement = ({ keyPage }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [listDataSourceOfTable, setListDataSourceOfTable] = useState(null)
  const [total, setTotal] = useState(0)

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const getListLinkZoom = async () => {
    setIsLoading(true)
    const dataSent = {
      skip:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
    }
    const { response } = await getListZoom(dataSent)
    if (response.status == 200) {
      setListDataSourceOfTable(response.data.data)
      setTotal(response.data.count)

      setIsLoading(false)
    }
  }

  const confirm = async (id) => {
    setIsLoading(true)
    const res = await delteZoom({ id })
    if (res.data) {
      getListLinkZoom()
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "red"
      )
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (keyPage == 5) {
      getListLinkZoom()
    }
  }, [keyPage])
  const columns = [
    {
      title: <div className="w-full text-center">{"No."}</div>,
      width: 310,

      render: (_, record) => {
        return <div>{record.id}</div>
      },
    },
    {
      title: <div className="w-full text-center">{"開催日"}</div>,
      width: 110,
      dataIndex: "date",
      render: (date) => {
        return <div>{moment(date).format("YYYY/MM/DD")}</div>
      },
    },
    {
      title: <div className="w-full text-center">{"開催時間"}</div>,
      width: 100,

      dataIndex: "date",
      render: (date) => {
        return <div>{moment(date).format("HH:mm")}</div>
      },
    },
    {
      title: <div className="w-full text-center">{"企業名"}</div>,

      render: (_, report) => {
        return (
          <ul className="mb-0  list-disc ">
            {report?.stores?.map((ele) => {
              return <li className="font-bold">{ele?.name}</li>
            })}
          </ul>
        )
      },
    },
    {
      title: <div className="w-full text-center">{"URL"}</div>,
      dataIndex: "link",
      render: (link) => {
        return (
          <div className="flex ">
            <Link to={link}>
              <Tooltip title={link}>
                {" "}
                <div className="line-clamp-1 w-[150px]">{link}</div>
              </Tooltip>
            </Link>
            <Paragraph copyable={{ text: link }}></Paragraph>
          </div>
        )
      },
    },
    {
      title: <div className="w-full text-center">{"タイトル"}</div>,
      dataIndex: "name",
      render: (name) => {
        return <div>{name}</div>
      },
    },
    {
      title: <div className="w-full text-center">{"操作"}</div>,
      width: "100px",
      fixed: "right",
      render: (_, data) => {
        return (
          <Space>
            <Popconfirm
              title="このズーム会議を削除してもよろしいですか?"
              onConfirm={() => confirm(data.id)}
              okText="削除"
              cancelText="キャンセル"
            >
              <Button>削除</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })
  }
  useEffect(() => {
    if (listDataSourceOfTable) {
      getListLinkZoom()
    }
  }, [JSON.stringify(tableParams)])
  return (
    <div style={{ width: "100%", background: "#fff", padding: "40px 50px" }}>
      <div style={{ padding: "40px 0px", background: "#FAFAFA" }}>
        <AddNewZoomLive getListLinkZoom={getListLinkZoom} />
      </div>
      <div style={{ width: "100%", marginTop: 40 }}>
        <Table
          scroll={{ x: 1200 }}
          bordered={true}
          columns={columns}
          dataSource={listDataSourceOfTable}
          loading={isLoading}
          onChange={handleTableChange}
          rowKey="id"
          pagination={{
            ...tableParams.pagination,
            total: total,
            itemRender: paginationCustom,
            showSizeChanger: false,
            showTotal: (total, range) => (
              <div>
                {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                {total}件)
              </div>
            ),
          }}
        />
      </div>
    </div>
  )
}

export default ZoomLiveManagement
