import { Rate, Switch, Tooltip } from "antd"
import React from "react"
import moment from "moment"
import { changeStatusReviewByAdmin } from "../../../services/superAdminReviewManage"
import NoImg from "../../../images/no_image.jpg"

export const defineTableListReview = (handleGetListReview) => {
  return [
    {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <span>問い合わせ番号</span>
          <span>注文番号</span>
        </div>
      ),
      width: 130,
      dataIndex: "id",
    },

    {
      title: (
        <div
          className="text-center   text-xs"
          style={{
            textAlign: "center",
          }}
        >
          発注日時
        </div>
      ),
      dataIndex: "created_at",
      key: "created_at",
      width: 70,
      render: (text) => (
        <div style={{ color: "#888888", textAlign: "center" }}>
          {moment(text).format("YYYY/MM/DD HH:mm")}
        </div>
      ),
    },

    {
      title: (
        <div
          className="text-center   text-xs"
          style={{
            textAlign: "center",
          }}
        >
          投稿者
        </div>
      ),
      dataIndex: ["store", "name"],
      width: 140,
      render: (text) => (
        <Tooltip title={text}>
          <div className="textCustom !text-black-5 line-clamp-1">{text}</div>
        </Tooltip>
      ),
    },

    {
      title: (
        <div
          className="text-center   text-xs"
          style={{
            textAlign: "center",
          }}
        >
          評価
        </div>
      ),
      dataIndex: "rating",

      width: 120,
      render: (text) => (
        <div className="text-[#EC5627] flex items-center">
          <Rate
            disabled
            allowHalf
            defaultValue={text}
            style={{ fontSize: 14, color: "#EC5627" }}
          />
        </div>
      ),
    },

    {
      title: (
        <div
          className="text-center   text-xs"
          style={{
            textAlign: "center",
          }}
        >
          コメント
        </div>
      ),
      width: 246,
      dataIndex: "comment",

      render: (text) => (
        <Tooltip title={text}>
          <div className="line-clamp-1 !text-black-5">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div
          className="text-center   text-xs"
          style={{
            textAlign: "center",
          }}
        >
          表示
        </div>
      ),

      width: 90,
      render: (_, record) => (
        <Switch
          checked={record.is_displayed}
          onChange={async (ele) => {
            console.log("ele", ele)
            const dataSent = {
              isDisplay: ele,
              assessIds: [record.id],
            }
            const { response } = await changeStatusReviewByAdmin(dataSent)
            if (response.status == 200) {
              handleGetListReview()
            }
          }}
        />
      ),
    },
  ]
}
