import { Button, Switch } from "antd"
import moment from "moment"
import React, { useContext, useState } from "react"
import { SuperAdSiteContext } from "../.."
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../../components/notification"
import { changeStatusCampaign } from "../../../../services/campaign"

export const defineTableListCampaign = (handleGetListCampaign) => {
  const { setIdCampaignDetail, setCampaignType } = useContext(
    SuperAdSiteContext
  )

  return [
    {
      title: "実施状況",
      render: (_, record) => (
        <div
          className={`w-[90px] h-[26px] flex items-center justify-center ${
            record.is_disabled
              ? "bg-[#FFF1F0] text-[#F64951]"
              : !record.ends_at || moment().isBefore(moment(record.ends_at))
              ? "bg-[#F0F5FF] text-[#2F54EB]"
              : "bg-[#EAEAEA] text-[#222222]"
          } `}
        >
          {record.is_disabled
            ? "下書き"
            : !record.ends_at || moment().isBefore(moment(record.ends_at))
            ? "実施中"
            : "終了"}
        </div>
      ),
    },
    {
      title: "開始日",
      dataIndex: "starts_at",
      key: "starts_at",
      render: (date) => (
        <span className="text-[#888]">
          {date ? moment(date).format("YYYY/MM/DD") : ""}
        </span>
      ),
    },
    {
      title: "終了日",
      dataIndex: "ends_at",
      key: "ends_at",
      render: (date) => (
        <span className="text-[#888]">
          {date ? moment(date).format("YYYY/MM/DD") : ""}
        </span>
      ),
    },
    {
      title: "キャッチコピー",
      dataIndex: "code",
    },
    {
      title: "割引率",

      render: (_, record) => (
        <span className="text-black-5 text-13 font-notoSans font-medium">
          {record.rule?.value ? `${record.rule?.value}%` : ""}
        </span>
      ),
    },
    {
      title: "社内メモ",

      render: (_, record) => (
        <span className="text-black-5 text-13 font-notoSans font-medium flex max-w-[400px] break-all">
          {record.rule?.description}
        </span>
      ),
    },
    {
      title: (
        <div style={{ fontSize: 12 }} className="w-full text-center">
          {"表示"}
        </div>
      ),
      width: 100,
      render: (_, record) => {
        return (
          <div>
            <Switch
              checked={!record.is_disabled}
              onChange={async (e) => {
                console.log(e)
                const { response }: any = await changeStatusCampaign(
                  record.id,
                  {
                    is_disabled: !e,
                  }
                )
                if (response.status == 200) {
                  openNotificationChangeSuccess(
                    "正常に更新されました。",
                    "",
                    "#389e0d"
                  )
                  handleGetListCampaign()
                } else {
                  openNotificationFail(
                    "ただいま、サーバが混み合っています。",
                    "しばらくしてから再度操作してください。",
                    "#f81d22"
                  )
                }
              }}
            />
          </div>
        )
      },
    },
    {
      title: "操作",
      dataIndex: "operate",
      key: "operate",
      render: (_, record) => (
        <Button
          style={{ display: "flex" }}
          className="items-center justify-center !text-sm !w-[45px] !h-[21px] font-notoSans !text-primary-90"
          danger
          onClick={() => {
            setIdCampaignDetail(record.id)
            setCampaignType("detail")
          }}
        >
          編集
        </Button>
      ),
    },
  ]
}
