import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { Button, Space, Table } from "antd"
import React, { useEffect, useState } from "react"
import { defineTableListReview } from "./defineTableListReview"
import { getListDataReview } from "../../../services/superAdminReviewManage"
import HeaderTable from "./HeaderTable"

function ReviewManagement({ keyPage }) {
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({})

  const [listReview, setListReview] = useState(null)
  const [total, setTotal] = useState(0)
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <>
          <Space>
            <Button style={{ borderRadius: "2px" }}>
              <a>
                <DoubleLeftOutlined
                  style={{ verticalAlign: "middle", fontSize: "1rem" }}
                />
              </a>
            </Button>
            <Button style={{ borderRadius: "2px" }}>
              <a className="font-semibold">前のページ</a>
            </Button>
          </Space>
        </>
      )
    }

    if (type === "next") {
      return (
        <>
          <Space>
            <Button style={{ borderRadius: "2px" }}>
              <a className="font-semibold">次のページ</a>
            </Button>
            <Button style={{ borderRadius: "2px" }}>
              <a>
                <DoubleRightOutlined
                  style={{ verticalAlign: "middle", fontSize: "1rem" }}
                />
              </a>
            </Button>
          </Space>
        </>
      )
    }
    return originalElement
  }
  const onSearchSubmit = (params) => {
    setSearchParams(params)
  }

  const handleGetListReview = async () => {
    setIsLoading(true)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      q: searchParams.keyword ? searchParams.keyword : null,
    }
    const { response } = await getListDataReview(dataSent)
    if (response.status == 200) {
      setListReview(response.data?.assessList)
      setTotal(response.data?.total)

      setIsLoading(false)
    }
  }
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })
  }

  useEffect(() => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    })
  }, [searchParams])

  useEffect(() => {
    if (keyPage == 3) {
      handleGetListReview()
    }
  }, [keyPage])

  useEffect(() => {
    if (listReview) {
      handleGetListReview()
    }
  }, [tableParams])
  const columns = defineTableListReview(handleGetListReview)

  return (
    <div style={{ width: "100%", background: "#fff", padding: "40px 50px" }}>
      <HeaderTable onSubmit={onSearchSubmit} loading={isLoading} />

      <Table
        scroll={{ x: 1300 }}
        locale={{ emptyText: "データなし" }}
        columns={columns}
        dataSource={listReview}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: total,
          itemRender: itemRender,
          showSizeChanger: false,
          showTotal: (total, range) => (
            <div>
              {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
              {total}件)
            </div>
          ),
        }}
      />
    </div>
  )
}

export default ReviewManagement
