import { Row, Col, Form, Input, InputNumber } from "antd"
import React from "react"
import { TableLeft } from "."
import textAndRules from "../../../../utils/textAndRules"

const DiscountRateFromUnitPrice = () => {
  return (
    <Row className="!flex border-t border-custom-border">
      <TableLeft tag="必須" type="red" title="御単価からの割引率" />
      <Col className="p-5 pr-[159px] flex-1 !flex items-center">
        <div className="mr-[5px]">
          <Form.Item
            name="vat"
            rules={[
              {
                required: true,
                message: textAndRules.pleaseInput,
              },
            ]}
          >
            <InputNumber
              style={{ height: 25, width: 110 }}
              type="number"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              min={0}
              max={100}
              addonAfter={
                <span className="text-xs font-medium text-[#595959]">
                  % OFF
                </span>
              }
            />
          </Form.Item>
        </div>
      </Col>
    </Row>
  )
}

export default DiscountRateFromUnitPrice
