import request from "../customUtil/request"

export async function getListDataReview(param) {
  return request("/admin/orders/assess/list", {
    method: "GET",
    params: param,
  })
}

export async function changeStatusReviewByAdmin(data) {
  return request(`/admin/orders/assess/change-display`, {
    method: "POST",
    data: data,
  })
}
