import { Row, Col, Form, DatePicker, Checkbox, Typography } from "antd"
import moment from "moment"
import React, { useState } from "react"
import { TableLeft } from "."

const { Item } = Form
const { Text } = Typography

const Period = ({ form }) => {
  const [startDateSelect, setStartDateSelect] = useState(null)
  const [endDateSelect, setEndDateSelect] = useState(null)
  const [disabledInputDate, setDisabledInputDate] = useState(false)
  const disabledDateStart = (current) => {
    // Can not select days before today and today
    if (endDateSelect) {
      return (
        current < moment().startOf("day") ||
        current > moment(endDateSelect).endOf("day")
      )
    }
  }
  const disabledDateEnd = (current) => {
    // Can not select days before today and today
    if (startDateSelect) {
      return current < moment(startDateSelect).startOf("day")
    }
    current < moment().startOf("day")
  }
  return (
    <Row className="!flex">
      <TableLeft tag="任意" type="normal" title="開催期間" />
      <Col className="p-5 flex  flex-wrap items-center child:flex child:items-center !flex-1">
        <div className="flex  flex-wrap">
          <div className="flex  items-center">
            <Item name="startDate">
              <DatePicker
                placeholder="2022年1月"
                disabled={disabledInputDate}
                onChange={(e) => {
                  setStartDateSelect(e)
                }}
                disabledDate={disabledDateStart}
              />
            </Item>
            <Text className="text-xs !text-black-7 font-medium w-[29px] mx-[5px]">
              から
            </Text>
          </div>
          <div className="flex  items-center">
            <Item name="endDate">
              <DatePicker
                placeholder="2022年1月"
                disabled={disabledInputDate}
                onChange={(e) => {
                  setEndDateSelect(e)
                }}
                disabledDate={disabledDateEnd}
              />
            </Item>
            <Text className="text-xs !text-black-7 font-medium w-[29px] ml-[5px]">
              まで
            </Text>
          </div>
        </div>
        <div className="ml-[30px]">
          <Item name="noLimit">
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  setEndDateSelect(null)
                  setStartDateSelect(null)
                  form.setFieldsValue({ startDate: null, endDate: null })
                  setDisabledInputDate(true)
                  return
                }
                setDisabledInputDate(false)
              }}
            >
              無期限
            </Checkbox>
          </Item>
        </div>
      </Col>
    </Row>
  )
}

export default Period
