import { Button, Col, Form, Modal, Tag, Typography } from "antd"
import moment from "moment"
import React, { useContext, useState } from "react"
import { SuperAdSiteContext } from "../.."
import { openCustomNotificationWithIcon } from "../../../../common/notifycation"
import { openNotificationFail } from "../../../../components/notification"
import { createCampagin } from "../../../../services/campaign"
import CampaignBanner from "./CampaignBanner"
import CampaignURL from "./CampaignURL"
import CatchCopy from "./CatchCopy"
import DiscountRateFromUnitPrice from "./DiscountRateFromUnitPrice"
import InternalMemo from "./InternalMemo"
import Period from "./Period"
import "./style.scss"

const { Text } = Typography

export const TableLeft = ({ tag, type, title }) => (
  <Col className="w-[232px] !flex items-center px-5 bg-[#F3F3F3]">
    <Tag
      className={` font-normal  ${
        type === "normal"
          ? "!bg-[#E4E8EB] !text-[12px] !text-black-0"
          : type === "red"
          ? "!bg-[#FFF1F0] !text-[11px] !text-[#E75B53]"
          : "bg-grey-0 text-black-0"
      }`}
    >
      {tag}
    </Tag>
    <Text className="text-[14px] font-bold">{title}</Text>
  </Col>
)

const AddCampaign = () => {
  const { setCampaignType } = useContext(SuperAdSiteContext)
  const [isLoading, setIsLoading] = useState(false)
  const [draftStatus, setDraftStatus] = useState(null)

  const [form] = Form.useForm()
  const onFinish = async (values) => {
    // setCampaignType("list")
    console.log("valuesvalues", values)
    setIsLoading(true)
    const dataSent = {
      code: values.catchCopy.toUpperCase(),
      is_disabled: draftStatus == 1 ? false : true,
      rule: {
        allocation: "total",
        type: "percentage",
        value: values.vat,
        description: values.internalMemo,
        conditions:
          values.products === "all" || values.url
            ? null
            : [
                {
                  operator: "in",
                  products: values.products?.map((e) => e.value),
                },
              ],
      },
      ends_at: values.endDate ? moment(values.endDate).format() : null,
      starts_at: values.startDate
        ? moment(values.startDate).format()
        : moment().format(),
      metadata: {
        url: values.file,
        products: values.products === "all" ? [] : values.products,
        link: values.url,
      },
    }
    const { response } = await createCampagin(dataSent)
    if (response.status == 200) {
      openCustomNotificationWithIcon(
        "success",
        "キャンペーン登録成でした。",
        ""
      )
      setCampaignType("list")
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "#f81d22"
      )
      setIsLoading(false)
    }
  }
  const handleSet = (name, link) => {
    form.setFieldsValue({ [name]: link })
  }
  return (
    <div className="bg-grey-0 p-[50px] pt-10 font-notoSans campaignRegistration">
      <Text className="text-sm font-medium !text-[#555] tracking-[0.04em] ">
        はじめに、キャンペーンの基本情報を入力してください。
      </Text>
      <div className="border border-custom-f3 mt-4">
        <Form onFinish={onFinish} form={form}>
          <div className="text-sm text-[#555] font-medium tracking-[0.04em] bg-[#eaeaea] p-[10px]">
            基本情報
          </div>
          <Period form={form} />
          <CatchCopy />
          <CampaignBanner handleSet={handleSet} />
          <DiscountRateFromUnitPrice />
          <CampaignURL form={form} />
          <InternalMemo />
        </Form>
      </div>
      <div className="flex items-center justify-center mt-[66px]">
        <Button
          className="!w-[105px] mr-[10px] !h-[37px]"
          onClick={() => {
            setCampaignType("list")
          }}
        >
          一覧に戻る
        </Button>
        <Button
          danger
          style={{ width: 176 }}
          className="!h-[37px] mr-[10px]"
          loading={isLoading}
          onClick={() => {
            form.submit()
          }}
        >
          {"下書き保存する"}
        </Button>
        <Button
          type="primary"
          loading={isLoading}
          htmlType="submit"
          onClick={() => {
            setDraftStatus(1)

            form.submit()
          }}
          style={{ width: 176 }}
          className=" !h-[37px]"
        >
          保存する
        </Button>
      </div>
    </div>
  )
}

export default AddCampaign
