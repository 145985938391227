import { Button, Col, Image, Popconfirm, Form, Row, Spin } from "antd"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { SuperAdSiteContext } from "../.."
import { openCustomNotificationWithIcon } from "../../../../common/notifycation"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../../components/notification"
import {
  createCampagin,
  deleteCampaign,
  getDataCampaign,
  updateCampain,
} from "../../../../services/campaign"
import { TableLeft } from "../addCampaign"
import CatchCopy from "../addCampaign/CatchCopy"
import InternalMemo from "../addCampaign/InternalMemo"
import Period from "../addCampaign/Period"
import CampaignBannerDetail from "./CampaignBannerDetail"
import DetailStep1 from "./DetailStep1"
import FooterHandle from "./FooterHandle"
import "./style.scss"

export const RowConfirm = ({ label, children }) => (
  <Row className="border-t border-custom-border !flex">
    <Col className="w-[232px] flex items-center pl-5 bg-[#F3F3F3] text-sm text-custom-34 font-bold font-notoSans">
      {label}
    </Col>
    <Col className="flex items-center pl-5 flex-1 font-notoSans">
      {children}
    </Col>
  </Row>
)

const DetailCampaign = () => {
  const { idCampaignDetail, campaignType } = useContext(SuperAdSiteContext)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [dataCampaign, setDataCampaign] = useState(null)
  const [draftStatus, setDraftStatus] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [step, setStep] = useState(1)
  const handleGetDataCampaign = async (id) => {
    setIsLoadingData(true)
    const { response } = await getDataCampaign(id)
    if (response.status == 200) {
      setIsLoadingData(false)
      setDataCampaign(response.data.discount)
    }
  }

  useEffect(() => {
    handleGetDataCampaign(idCampaignDetail)
  }, [idCampaignDetail, campaignType])

  const [form] = Form.useForm()

  const onFinish = async (values) => {
    // setCampaignType("list")
    console.log("valuesvalues", values)
    setIsLoading(true)
    const dataSent = {
      code: values.catchCopy.toUpperCase(),
      is_disabled: draftStatus == 1 ? false : true,
      rule: {
        id: dataCampaign.rule?.id,
        description: values.internalMemo,
      },
      ends_at: values.endDate ? moment(values.endDate).format() : null,
      starts_at: values.startDate
        ? moment(values.startDate).format()
        : moment().format(),
      metadata: {
        ...dataCampaign.metadata,
        url: values.file,
      },
    }
    const { response } = await updateCampain(idCampaignDetail, dataSent)
    if (response.status == 200) {
      openNotificationChangeSuccess("正常に更新されました。", "", "#389e0d")
      setDataCampaign(response.data.discount)
      setIsLoading(false)
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "#f81d22"
      )
      setIsLoading(false)
    }
  }
  const handleSet = (name, link) => {
    form.setFieldsValue({ [name]: link })
  }

  return (
    <Spin spinning={isLoadingData}>
      {dataCampaign && (
        <div className="bg-grey-0 p-[50px] pt-10 campaignRegistration">
          <Row className="!flex border-y border-[#d9d9d9]">
            <TableLeft tag="必須" type="red" title="実施状況" />
            <Col className="p-5 pr-[159px] flex-1 bg-grey-0">
              <div
                className={`w-[90px] h-[26px] p-[10px] flex items-center justify-center ${
                  dataCampaign.is_disabled
                    ? "bg-[#FFF1F0] text-[#F64951]"
                    : !dataCampaign.ends_at ||
                      moment().isBefore(moment(dataCampaign.ends_at))
                    ? "bg-[#F0F5FF] text-[#2F54EB]"
                    : "bg-[#EAEAEA] text-[#222222]"
                } `}
              >
                {dataCampaign.is_disabled
                  ? "下書き"
                  : !dataCampaign.ends_at ||
                    moment().isBefore(moment(dataCampaign.ends_at))
                  ? "実施中"
                  : "終了"}
              </div>
            </Col>
          </Row>
          <div className="border border-custom-f3 mt-4">
            <Row className="!flex justify-between bg-grey-7 h-[45px] p-[10px_10px_14px_10px]">
              <span className="text-sm text-custom-5 font-notoSans font-medium tracking-4">
                基本情報
              </span>
              {step == 1 && (
                <button
                  className="text-primary-90 text-sm font-bold border border-primary-90 rounded w-[45px] h-[25px] flex items-center justify-center"
                  onClick={() => {
                    setStep(2)
                  }}
                >
                  編集
                </button>
              )}
            </Row>
            {step == 1 && <DetailStep1 dataCampaign={dataCampaign} />}
            {step == 2 && (
              <Form
                onFinish={onFinish}
                form={form}
                initialValues={{
                  startDate: moment(dataCampaign.starts_at),
                  endDate: dataCampaign.ends_at
                    ? moment(dataCampaign.ends_at)
                    : null,
                  catchCopy: dataCampaign.code,
                  vat: dataCampaign.rule?.value,
                  internalMemo: dataCampaign.rule?.description,
                  file: dataCampaign.metadata?.url,
                }}
              >
                <Period form={form} />
                <CatchCopy />
                <CampaignBannerDetail
                  handleSet={handleSet}
                  dataCampaign={dataCampaign}
                />
                <RowConfirm
                  label={"御単価からの割引率"}
                  children={
                    <span className="py-[28px] text-sm font-medium text-[#595959]">
                      {`${dataCampaign.rule?.value}% OFF`}
                    </span>
                  }
                />
                <RowConfirm
                  label={"対象商品"}
                  children={
                    <span className="py-5 text-13 font-medium text-black-5">
                      {dataCampaign.metadata?.link ? (
                        <div>
                          転送先URL:{" "}
                          <a href={dataCampaign.metadata?.link} target="_blank">
                            {dataCampaign.metadata?.link}
                          </a>
                        </div>
                      ) : (
                        dataCampaign.metadata?.products &&
                        (dataCampaign.metadata?.products?.length == 0
                          ? "全商品"
                          : dataCampaign.metadata?.products?.map(
                              (el, index) => {
                                if (index === 0) {
                                  return el.label
                                } else {
                                  return `, ${el.label}`
                                }
                              }
                            ))
                      )}
                    </span>
                  }
                />
                <InternalMemo />
              </Form>
            )}
          </div>
          <FooterHandle
            dataCampaign={dataCampaign}
            step={step}
            setStep={setStep}
            setDraftStatus={setDraftStatus}
            isLoading={isLoading}
            form={form}
          />
        </div>
      )}
    </Spin>
  )
}

export default DetailCampaign
