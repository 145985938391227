import { Row, Col, Form } from "antd"
import TextArea from "antd/lib/input/TextArea"
import React from "react"
import { TableLeft } from "."
import "./style.scss"

const InternalMemo = () => {
  return (
    <Row className="!flex border-t border-custom-border">
      <TableLeft tag="任意" title="社内メモ" />
      <Col className="p-5 flex-1">
        <Form.Item name="internalMemo">
          <TextArea maxLength={500} placeholder="※全角または半角500字以内" />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default InternalMemo
