import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"
import SuperAdSiteManagement from "../../domain/superAdSiteManagement"
import "./style.scss"

const index = ({ location }) => {
  return (
    <div className="resetLayout">
      <Topbar />
      <CustomLayout
        selectedPage={<SuperAdSiteManagement location={location} />}
      />
    </div>
  )
}

export default index
