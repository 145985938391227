import request from "../customUtil/request"

export async function getListCampaign(param) {
  return request("admin/discounts", {
    method: "GET",
    params: param,
  })
}

export async function getDataCampaign(id) {
  return request(`/admin/discounts/${id}`, {
    method: "GET",
  })
}
export async function createCampagin(data) {
  return request("admin/discounts", {
    method: "POST",
    data: data,
  })
}

export async function updateCampain(id, data) {
  return request(`admin/discounts/${id}`, {
    method: "POST",
    data: data,
  })
}

export async function changeStatusCampaign(id, data) {
  return request(`/admin/discounts/${id}`, {
    method: "POST",
    data: data,
  })
}

export async function deleteCampaign(data) {
  return request("/admin/super/campaign/delete", {
    method: "DELETE",
    data: data,
  })
}
